import React from 'react'
import AboutSection from '../../components/UI/aboutsection/AboutSection'
import AboutTheFounder from '../../components/UI/aboutsection/AboutTheFounder'
import Achievements from '../achievements/Achievements'

const About = () => {
  return (
    <div className='about'>
    <AboutSection/>
    <Achievements/>
    <AboutTheFounder/>
    </div>
  )
}

export default About