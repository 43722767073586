import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "./Achievements.css";

const Achievements = () => {
  const achievements = [
    {
      image: "/images/a1.jpg",
      title: "EUREKA Zonalist",
      description: "Organized by E-Cell, IIT Bombay",
    },
    {
      image: "/images/a3.png",
      title: "Top Performer",
      description: "Tejaswini - Assam Ideathon 2024",
    },
    {
      image: "/images/a6.png",
      title: "Meity Tide 2.0",
      description: "Successful Completion",
    },
    {
      image: "/images/a5.png",
      title: "Assam Startup",
      description: "Cohort5.0 Completion",
    },
  ];

  return (
    <div className="achievements">
      <div className="achievements-header">
        <h2>Our Achievements</h2>
        <p>
          Here are some milestones that define our journey of excellence.
        </p>
      </div>
      <div className="achievements-carousel">
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          interval={5000}
          dynamicHeight={false}
        >
          {achievements.map((item, index) => (
            <div key={index} className="achievement-item">
              <img src={item.image} alt={item.title} />
              <div className="achievement-overlay">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Achievements;
