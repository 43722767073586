import React from 'react'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <div class="privacy-policy-content">
    <h1>Privacy Policy</h1>
    <p><strong>Effective Date:</strong> 01/01/2025</p>
    <p>
      This Privacy Policy outlines how The HummingBird (referred to as "we,"
      "us," or "our") collects, uses, discloses, and protects personal
      information gathered from users of our website and related services. By
      accessing or using our website, you agree to the terms of this Privacy
      Policy.
    </p>

    <h2>Information We Collect</h2>
    <p>
      Our services collect and process several types of personal information
      to ensure seamless operations and enhance the quality of services
      provided. We collect and store specific details provided by our users to
      facilitate communication, authentication, and service delivery. This
      includes:
    </p>
    <ul>
      <li>
        <strong>Phone Numbers of Users:</strong> Collected during registration
        or service request initiation to verify identity, send updates, and
        provide timely assistance.
      </li>
      <li>
        <strong>Contact Details of Ambulance Service Providers:</strong> This
        includes their official phone numbers and ambulance license numbers,
        which are essential for connecting patients with reliable and verified
        ambulance services.
      </li>
      <li>
        <strong>Driver Contact Information: </strong> The contact numbers of
        ambulance drivers are gathered to establish direct communication
        between drivers and users or service administrators, ensuring prompt
        coordination during emergencies.
      </li>
    </ul>

    <p>
      To provide accurate and efficient emergency services, we collect and
      process location-based data in real-time:
    </p>

    <ul>
      <li>
        <strong>Real-time Location of Patients:</strong>GPS technology is used
        to determine the precise location of the patient requiring assistance.
        This data is crucial for identifying the closest ambulance and
        expediting response times.
      </li>
      <li>
        <strong>Real-time Location of Ambulances:</strong> Ambulance GPS data
        is monitored while the service is active. This information allows us
        to track the ambulance's route, estimated arrival time, and ensure
        efficient navigation to the patient’s location or destination.
      </li>
    </ul>

    <p>
      By collecting this information, we strive to provide an efficient and
      reliable emergency response system. All data is handled with utmost
      confidentiality and in compliance with applicable privacy laws to
      protect the rights and privacy of all stakeholders.
    </p>

    <h2>How We Collect Information</h2>
    <p>
      Users provide their phone numbers to request a callback from registered ambulance services. This enables a seamless connection between patients in need and available ambulance providers, ensuring timely assistance during emergencies. For ambulance service providers, the registration process is designed to be simple and accessible. They can register their services through a dedicated link or by scanning a QR code, which streamlines the onboarding process and ensures that only verified providers are included in the network. This dual system ensures efficiency and reliability for both users and service providers
    </p>

    <h2>Purpose of Collecting Information</h2>
    <p>
      The personal information we collect is utilized with the primary objective of delivering efficient and reliable emergency response services while maintaining a high standard of user experience. The purposes of collecting and processing this information are detailed as follows:
    </p>


    <ul>
      <li>
        <strong>Facilitating Emergency Connection</strong>The primary purpose of collecting user and service provider information is to enable seamless and effective communication between end users and registered ambulance services during emergencies. By using the provided phone numbers and GPS data, our platform ensures that patients in need can quickly connect with the nearest available ambulance services, minimizing delays and optimizing response times in critical situations.
      </li>
      <li>
        <strong>Operational and Functional Support</strong> The information collected forms the backbone of our ambulance integration platform, ensuring its smooth operation and functionality. User data, ambulance provider details, and real-time location tracking are integral to matching patients with appropriate service providers, enabling navigation, and maintaining transparency throughout the service process. This data also supports the platform’s essential features, such as live updates, route optimization, and direct communication between users and service providers.
      </li>
      <li><strong>Enhancing Service Delivery Through Research and Development</strong>
          We are committed to continuously improving our services, particularly during the Research and Development (R&D) phase of our startup. The collected data is analyzed to identify operational challenges, enhance platform performance, and develop innovative solutions tailored to user needs. This helps us refine our services, improve system efficiency, and implement advanced features that ensure better outcomes for users and service providers alike.
      </li>
    </ul>


    <h2>Data Retention and Storage</h2>
    <p>
      The collected information is securely stored on our registration platform, utilizing advanced security measures to protect it from unauthorized access or breaches. This information is retained for as long as ambulance service providers choose to remain registered with our platform, ensuring its availability for operational purposes. Once a provider decides to deregister or discontinue their services, their information is promptly removed from our system, maintaining a strict policy of retaining data only for as long as necessary. This approach ensures both security and compliance with data protection standards.
    </p>

    <h2>Sharing of Information</h2>
    <p>
      We do not share personal information with any third parties.
    </p>

    <h2>Protection of Information</h2>
    <p>We employ reasonable and industry-standard measures to safeguard personal data, including encryption, secure servers, and restricted access.</p>

    <h2>Use of Cookies and Tracking Technologies</h2>
    <p>
      Cookies are small text files stored on your device by a website you visit. They are designed to collect and remember information about your interaction with the website, such as preferences, login details, or session activities. Cookies play a crucial role in enhancing user experience by enabling websites to function efficiently, remember user preferences, and deliver personalized content or features.
    </p>

    <p>We use essential and functional cookies to improve the overall experience of our website. Essential cookies are necessary for the website's core functionalities, such as navigation, security, and accessibility. Without these cookies, the website cannot function properly. Functional cookies, on the other hand, help us provide additional features, such as remembering your preferences and enabling smoother navigation, ensuring a more seamless and tailored user experience.</p>

    <p>Users have the option to disable cookies through their browser settings if they prefer not to store this data. However, please note that disabling cookies may limit certain functionalities of the website. While the site will remain accessible, some features that rely on cookie-based data, such as personalized settings or real-time session management, may not function as intended. This flexibility ensures users retain control over their data while continuing to access our services.</p>

    <h2> Access by Users Under the Age of 13</h2>
    <p>
      Our services are accessible to users under the age of 13; however, we are committed to ensuring the privacy and safety of children who interact with our platform. To comply with applicable privacy laws and protect the interests of minors, we require that parents or legal guardians supervise the use of our website and services by children.
    </p>
    <p>Parents or guardians must provide consent for their children under 13 to access our services. This ensures that any personal information collected is done so with full knowledge and authorization from a responsible adult. We do not knowingly collect personal data directly from children under 13 without verifiable parental consent. If we become aware that we have inadvertently collected information from a child under this age without the necessary parental approval, we will take immediate steps to delete such information from our records.</p>

    <p>To support parental oversight, we encourage parents and guardians to familiarize themselves with our privacy policy, monitor their child’s online activities, and actively participate in decisions regarding their child's use of our services. Our platform is designed to foster a secure and respectful environment while prioritizing compliance with relevant child protection and data privacy regulations.</p>

    <h2>User Rights</h2>
    <p>Users have the right to:</p>
    <ul>
      <li>Access, update, or delete their personal information by contacting us.</li>
      <li>Decline cookies or tracking technologies without losing access to the website.</li>
    </ul>

    <h2>Data Usage for Health or Marketing Purposes</h2>
    <p>Currently, we do not utilize health data for recommendations, analysis, or marketing purposes.</p>

    <h2>Contact Information</h2>
    <p>For inquiries, support, or privacy-related concerns, users can reach us via:</p>
    <p><strong>Phone:</strong> 9886290234 OR 8929431871 </p>
    <p><strong>Address:</strong>House No.12, Janapath, Bye Lane-1, Navin Nagar, PO Zoo Road, Guwahati-24, Assam

    </p>

    <h2>Updates to this Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated "Effective Date." Continued use of our services constitutes acceptance of the revised policy. By using The HummingBird’s website and services, you acknowledge that you have read and understood this Privacy Policy.
    </p>
  </div>
  )
}

export default PrivacyPolicy